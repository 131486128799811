.app {
  font-family: 'Inter', sans-serif;
}
.Tile {
  border-radius: 5px !important;
  text-align:center !important;
  width:75px !important;
  height:75px !important;
  background-color:#cbcbcb !important;
}
.Hexa {
  position: relative !important;
  overflow: hidden !important;
  /* add slash at the end of line to see the rhombus *
  outline: solid 1px red;/**/
  width: 75px !important; 
  height: 75px !important; 
  transform: rotate(0deg) skewX(30deg) scaleY(.866) !important;
}
.Hexa:before {
  position: absolute !important;
  right: 6.7%; bottom: 0; left: 6.7%; top: 0 !important;
  transform: scaleY(1.155) skewX(-30deg) rotate(30deg) !important;
  background: #cbcbcb !important;
  font: 1em/10 Verdana, sans-serif !important;
  content: '' !important;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.MuiOutlinedInput-root {
  border-radius: 0px !important;
}
.Hallway {
  background-image: url('/public/image/previews/Hallway.webp');
  background-repeat: no-repeat;
  background-size: 100%;
  width: 560px;
  background-position: inherit;
  position: absolute;
  top: 150px;
  margin-left: 60px;
  height: 100% !important;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 1000;
}
.Hallway_cube_multiple {
  width: 134px;
  height: 134px;
}
.Hallway_cube {
  width: 67px;
  height: 67px;
}
.cleaner{
  position: absolute;
  width: 768px;
  height: 100px;
  background-color: white;
  margin-top: 700px;
  z-index: 7;
}
.Hallway_plane {
  height: 60px;
  width: 560px;
  scale: 0.70;
  margin-left: 20px;
  margin-top:   208px;
  /* display: grid;
  align-items: center; */
  transform: perspective(970px) rotateX(69deg) rotateY(0deg) rotateZ( 0deg);
}
.Hallway_plane_floor {
  height: 60px;
  width: 560px;
  margin-left: 20px;
  margin-top: 280px;
  display: grid;
  align-items: center;
  transform: perspective(850px) rotateX(55deg) rotateY(0deg) rotateZ(0deg);
}
.Hallway_plane_hexagon {
  height: 60px;
  margin-top: 232px;
  margin-left: 21px;
  display: grid;
  align-items: center;
  transform: perspective(850px) rotateX(55deg) rotateY(0deg) rotateZ(0deg);
}
.Hallway_plane_hexagon_floor {
  height: 60px;
  margin-top: 200px;
  display: grid;
  align-items: center;
  transform: perspective(850px) rotateX(55deg) rotateY(0deg) rotateZ(0deg);
}
.Bathroom {
  background-image: url('/public/image/previews/Bathroom.webp');
  background-repeat: no-repeat;
  background-size: 100%;
  width: 600px ;
  background-position: inherit;
  position: absolute;
  top: 120px;
  z-index: 1;
  height: 89% !important;
}

.Bathroom_plane {
  display: grid;
  margin-top: 122px;
  margin-right: -10px;
  margin-left: -123px;
  width: 700px;
  align-items: center;
  transform: perspective(1000px) rotateX(0deg) rotateY(0deg) rotateZ(180deg);
}
.Bathroom_plane_hexagon {
  display: grid;
  margin-top: 62px;
  margin-left: -73px;
  align-items: center;
  transform: perspective(1000px) rotateX(0deg) rotateY(0deg) rotateZ(180deg);
}
.Bathroom_plane_floor {
  display: grid;
  z-index: -1;
  margin-top: -77px;
  opacity: 1;
  margin-right: -10px;
  margin-left: -33px;
  width: 635px;
  align-items: center;
  transform: perspective(1150px) rotateX(65deg) rotateY(0deg) rotateZ(180deg);
}
.Bathroom_plane_hexagon_floor {
  display: grid;
  position: absolute;
  z-index: -1;
  margin-top: 530px;
  opacity: 1;
  margin-right: -12px;
  margin-left: -379px;
  height: 450px;
  width: 635px;
  scale: 1.135;
  align-items: center;
  transform: perspective(1150px) rotateX(65deg) rotateY(0deg) rotateZ(180deg);
}
.Bathroom_cube_floor {
  width: 126px;
  height: 126px;
}
.Bathroom_cube {
  width: 118px;
  height: 118px;
}
.Kitchen {
  background-image: url('/public/image/previews/Kitchen.webp');
  background-repeat: no-repeat;
  background-size: 100%;
  width: 600px;
  background-position: inherit;
  position: absolute;
  top: 150px;
  z-index: 1;
  height: 88% !important;
}
.Kitchen_plane {
  width:650px;
  height: 272px;
  margin-top: 205px;
  margin-left: 146px;
  align-items: center;
  transform: perspective(860px) rotateX(0deg) rotateY(-26deg) rotateZ(0deg);
}
.Kitchen_plane_hexagon {
  display: grid;
  margin-top: 55px;
  width: 150px;
  margin-left: 74px;
  align-items: center;
  transform: perspective(860px) rotateX(0deg) rotateY(-30deg) rotateZ(90deg);
}
.Kitchen_cube {
  width: 68px;
  height: 68px;
}
.Kitchen_cube_multiple {
  width: 119px;
  height: 119px;
}
.polygon_container {
  border: 1px solid black !important;
  clip-path: polygon(0% 0%,100% 0%,100% 100%,0% 100%);
}
.polygon_container_modal {
  clip-path: polygon(0% 0%,100% 0%,100% 100%,0% 100%);
  width: 250px;
  height: 515px;
}
.polygon_container_modal_bathroom {
  transform: rotateZ(90deg);
  clip-path: polygon(0% 0%,100% 0%,100% 100%,0% 100%);
  width: 325px;
  height: 525px;
}
.polygon_container_modal_hallway {
  transform: rotateZ(180deg);
  clip-path: polygon(0% 0%,100% 0%,100% 100%,0% 100%);
  width: 480px;
  height: 295px;
}

.Input_Login {
  border-radius: 50px !important;
}
.admin_tiles {
  border-radius: 50px !important;
}
.login_button{
  border-radius: 50px !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  background-color: #E7C8CD !important;
  box-shadow: none !important;
  color:black !important;
}
.login_image {
  background-image: url('/public/image/AdminLogin.webp');
  background-repeat: no-repeat;
  height:100vh;
  
}
.admin_layout_buttons {
  border-radius: 50px !important;
  background-color: #EFEFEF !important;
  box-shadow: none !important;
  color: black !important;
  font-weight: 600 !important;
  padding:10px !important;
}
.admin_layout_buttons:focus {
  background-color: #262626 !important;
  color: white !important;
}
.delete_request_button{
  color:white !important;
  background-color: #262626 !important;
  border-radius: 50px !important;
}
.burn_svg_tile {
  background-image: url('/public/image/texture.png');
  background-size: cover;
  pointer-events: none;
  background-repeat: no-repeat;
  opacity: 0.4;
  position: absolute;
  filter: brightness(65%);
  z-index: 2;
  width: 100%;
  height: 100%;
  }
  .FirstKitchenShadow{
    position: absolute;
    width: 475px;
    top:2px;
    z-index: 10;
  }
  .SecondKitchenShadow{
    position: absolute;
    width: 100px;
    height: 75px;
    top:72px;
    left: 80px;
  }
  .ThirdKitchenShadow{
    position: absolute;
    width: 100px;
    height: 75px;
    top:69px;
    left: 210px;
  }
  .FourthKitchenShadow{
    position: absolute;
    top: -112px;
    scale: 0.12;
    left: -655px;
  }
  .FiveKitchenShadow {
    position: absolute;
    scale: 0.1;
    width: 3600px;
    height: 2600px;
    top:-1150px;
    left: -1445px;
  }
  .SixKitchenShadow {
    position: absolute;
    width: 445px;
    top: 20px;
    height: 50px;
    background-color: rgb(16, 15, 15);
    opacity: 0.7;
    filter: blur(20px);
  }
  .SevenKitchenShadow {
    position: absolute;
    background-color: black;
    height: 100%;
    width: 200px;
    left: -35px;
    filter: blur(40px);
    opacity: 0.3;
  }
  .EightKitchenShadow {
    position: absolute;
    background-color: black;
    height: 100%;
    width: 50px;
    left: -10px;
    opacity: 0.8;
    filter: blur(30px);
  }
  .FirstHallwayShadow {
    position: absolute;
    background-color: black;
    filter: blur(5px);
    opacity: 0.7;
    top: 97px;
    left: 164px;
    width: 110px;
    height: 100px;
    transform: rotateZ(80deg);
  }
  .SecondHallwayShadow {
    position: absolute;
    background-color: black;
    filter: blur(3px);
    opacity: 0.7;
    top: 74px;
    left: 433px;
    width: 50px;
    height: 150px;
    transform: rotateZ(68deg);
  }
  .FirstHallwayShadowHexa {
    position: absolute;
    background-color: black;
    filter: blur(10px);
    opacity: 0.6;
    top: 127px;
    left: 164px;
    width: 110px;
    height: 100px;
    transform: rotateZ(80deg);
  }
  .SecondHallwayShadowHexa {
    position: absolute;
    background-color: black;
    filter: blur(8px);
    opacity: 0.6;
    top: 95px;
    left: 351px;
    width: 50px;
    height: 220px;
    transform: rotateZ(85deg);
  }
  
.Bathroom_Bottom_Shadow{
  position: absolute;
  bottom: -91px;
  left:-53px;
  width: 310px;
  height:650px;
  scale: 0.55;
  transform: rotateZ(180deg);
}
.Bathroom_Sink_Shadow {
  position: absolute;
  width: 380px;
  height: 60px;
  top: 180px;
  left: 210px;
  z-index: 18;
}
.Bathroom_Sink_Shadow2{
  position: absolute;
  width: 610px;
  top: -70px;
  left: 90px;
  scale: 0.6;
  transform: rotateZ(180deg);
  height: 400px;
}
.Bathroom_Border_Shadow{
  position: absolute;
  width: 505px;
  top: 289px;
}
.Bathroom_Floor_Shadow{
  position: absolute;
  width: 370px;
  top: 76px;
  left: 210px;
  transform: rotateZ(180deg);
}
.Bathroom_Sink_Shadow_Hexa{
  position: absolute;
  width: 380px;
  height: 60px;
  top: 180px;
  left: 124px;
  z-index: 18;
}
.Bathroom_Floor_Shadow_Hexa {
  position: absolute;
  width: 370px;
  top: 185px;
  left: 56px;
  transform: rotateZ(180deg);
}
.Bathroom_Border_Shadow_Hexa {
  position: absolute;
  width: 505px;
  top: 394px;
  left: -80px;
}
.Bathroom_Bottom_Shadow_Hexa{
    position: absolute;
    bottom: -32px;
    left:-220.5px;
    width: 310px;
    height:650px;
    scale: 0.55;
    transform: rotateZ(180deg);
  }
  .Bathroom_Sink_Shadow2_Hexa{
    position: absolute;
    width: 610px;
    top: 23px;
    left: -66px;
    scale: 0.6;
    transform: rotateZ(180deg);
    height: 400px;
  }
  .FirstKitchenShadowHexa{
    position: absolute;
    width: 475px;
    top:140px;
    left: -215px;
    transform: rotateZ(-90deg);
  }
  .SecondKitchenShadowHexa{
    position: absolute;
    width: 100px;
    height: 75px;
    top:297px;
    left: 20px; 
    transform: rotateZ(-90deg);
  }
  .ThirdKitchenShadowHexa{
    position: absolute;
    width: 100px;
    height: 75px;
    top:69px;
    left: 210px;
  }
  .FourthKitchenShadowHexa{
    position: absolute;
    top: 35px;
    scale: 0.12;
    left: -555px;
    transform: rotateZ(-90deg);
  }
  .FiveKitchenShadowHexa {
    position: absolute;
    scale: 0.1;
    width: 3600px;
    height: 2600px;
    top:-1100px;
    left: -1605px;
  }
  .SixKitchenShadowHexa {
    position: absolute;
    width: 480px;
    top: 410px;
    left: -100px;
    height: 50px;
    background-color: rgb(16, 15, 15);
    opacity: 0.7;
    filter: blur(20px);
  }
  .SevenKitchenShadowHexa {
    position: absolute;
    background-color: black;
    height: 100%;
    width: 200px;
    left: -35px;
    filter: blur(40px);
    opacity: 0.3;
  }
  .EightKitchenShadowHexa {
    position: absolute;
    background-color: black;
    height: 100%;
    width: 50px;
    left: -10px;
    opacity: 0.8;
    filter: blur(30px);
  }

  /* Admin css*/
  .downloadContainer{
    background-color: #f9f9f9;
    border: 2px dashed #7BB0FF;
    border-radius: 20px;
    height: 330px !important;
    width: 330px !important;
    margin-top: 40px;
  }
/*
  width="347px" height="643px"
*/
/*Preview images filter*/
/*.Bathroom_wall_shadow {
    background-image: url("Wall_Shadow.png");
    position: absolute;
    left: 728px;
    top: 533px;
    width: 347px;
    height: 643px;
    z-index: 18;
  }

.Bathroom_Sink_Shadow_copy {
  background-image: url("Sink_Shadow copy.png");
  opacity: 0.102;
  position: absolute;
  left: -13px;
  top: 633px;
  width: 727px;
  height: 339px;
  z-index: 13;
}
.Bathroom_Sink_Shadow {
  background-image: url("Sink_Shadow.png");
  position: absolute;
  left: -13px;
  top: 633px;
  width: 727px;
  height: 339px;
  z-index: 12;
}
.Bathroom_Floor_Shadow {
  background-image: url("Floor_Shadow.png");
  position: absolute;
  left: 0px;
  top: 1083px;
  width: 991px;
  height: 229px;
  z-index: 11;
} 
.Bathroom_Border_Shadow {
  background-image: url("Border_Shadow.png");
  position: absolute;
  left: 0px;
  top: 512px;
  width: 903px;
  height: 40px;
  z-index: 10;
}
*/